import { IS_APP, IS_DEV } from "@/__main__/constants.mjs";
import router from "@/__main__/router.mjs";
import fetchRemoteAdConfig from "@/feature-ads/fetch-remote-ad-config.mjs";
import { createPlaceholder } from "@/feature-ads/global-video-ad.mjs";
import { safeAppend } from "@/util/helpers.mjs";

const adElement = createPlaceholder({
  pageMacro: "post-match",
});
if (adElement) {
  adElement.style.flexShrink = "0";
}

export async function setupPostmatchPlaceholder(container: Document) {
  let shouldShow: boolean = IS_DEV || !IS_APP; // always show on dev and web
  shouldShow ||= router.route.state.isProgrammatic; // in-app only show on auto postmatch
  shouldShow ||= router.route.searchParams.has("postmatch"); // in-app only show on auto postmatch
  if (!shouldShow) return;

  const adContainer = container.querySelector(".match-header .right");
  if (!adContainer) return;

  const config = await fetchRemoteAdConfig();
  if (!config.videoEnabled) return;

  safeAppend(adContainer, adElement);
}
