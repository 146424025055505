import appRefs from "@/app/refs.mjs";
import fetchRemoteAdConfig from "@/feature-ads/fetch-remote-ad-config.mjs";
import { createPlaceholder } from "@/feature-ads/global-video-ad.mjs";
import adsRefs from "@/feature-ads/refs.mjs";
import { promiseTimeout } from "@/feature-ads/util/promise-timeout.mjs";
import { ProfileLayout } from "@/shared/Profile.style";
import globals from "@/util/global-whitelist.mjs";
import { safeAppend } from "@/util/helpers.mjs";

const adElement = createPlaceholder({
  pageMacro: "profile",
});
if (adElement) {
  adElement.style.flexShrink = "0";
}

export async function setupProfilePlaceholder(_container: ParentNode) {
  // the only common profile wrapper that includes the header is in this
  // component, we "render" it once to extract goober's class name
  const containerClass = ProfileLayout({}).props.className;

  const adContainer = globals.document.querySelector(
    `.${containerClass} .page-header .inner-container`,
  );
  if (!adContainer) return;

  const config = await fetchRemoteAdConfig();
  if (!config.videoEnabled) return;

  await promiseTimeout(
    appRefs.fetchedFeatureFlags,
    10 * 1000,
    "Awaiting Feature Flags",
  );
  if (!adsRefs.showProfileVideo) return;

  safeAppend(adContainer, adElement);
}
